import request from '@/utils/request'

export function search (data) {
  return request({
    url: '/shopify/products/search',
    method: 'post',
    data
  })
}

export function storeProduct (data) {
  return request({
    url: '/shopify/products',
    method: 'post',
    data
  })
}

export function getProduct (id) {
  return request({
    url: '/shopify/products/' + id,
    method: 'get'
  })
}

export function updateProduct (data, id) {
  return request({
    url: '/shopify/products/' + id,
    method: 'put',
    data
  })
}

export function getError (id) {
  return request({
    url: '/shopify/products/error/' + id,
    method: 'get'
  })
}

export function fixProduct (data, id) {
  return request({
    url: '/shopify/products/fix/' + id,
    method: 'post',
    data
  })
}

export function deleteProduct (id) {
  return request({
    url: '/shopify/products/' + id,
    method: 'delete'
  })
}

export function getPage (page, noitems, data) {
  return request({
    url: '/shopify/products/search?page=' + page + '&item=' + noitems,
    method: 'post',
    data
  })
}

export function uploadFile (data, config) {
  return request({
    url: '/shopify/products/load',
    method: 'post',
    data,
    ...config
  })
}

export function exportExcel (data) {
  return request({
    url: '/device/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
