<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :md="14" :sm="13" :xs="24">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID producto, título, vendor, tipo" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :lg="8" :md="10" :sm="11" :xs="24">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleCreate"
          size="mini"
          type="success"
          icon="el-icon-circle-plus-outline"
        >Crear</el-button>
      </el-col>
      <!-- <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="handleImport"
          size="mini"
          type="success"
          icon="el-icon-upload2"
        >Importar</el-button>
      </el-col> -->
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="4" :xs="24">
          <el-select v-model="searchForm.keys" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Claves SAT">
            <el-option
              v-for="state in stateKeys"
              :key="state"
              :label="state"
              :value="state">
            </el-option>
          </el-select>
      </el-col>
      <el-col class="grid-content" align="center" :lg="20" :md="20" :sm="20" :xs="24" style="display: flex; justify-content: flex-end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
      <el-table-column label="ID Shopify" fixed width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{scope.row.id_product_sh}}</div>
              <span>{{ scope.row.id_product_sh.toString().substr(-4) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Título" width="250" align="center">
        <template slot-scope="scope">
           <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SAT Prod/serv" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sat_prod_serv }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SAT Unidad" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sat_unidad }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SAT Obj. Impuesto" width="130" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sat_objImp }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Vendor" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.vendor }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Tipo" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.product_type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Publicado"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.published_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column label="Estatus" width="120" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Alcance" width="120" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.published_scope }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Tags" width="120" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.tags }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="Registro"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.created_at_sh | formatDate }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Opciones"  width="180" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)"
            >Editar</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click.native.prevent="handleDelete(scope.$index, scope.row)"
            >Eliminar</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  <el-dialog
    title="Importar catalogo Excel"
    :visible.sync="dialogImportFormVisible"
    width="30%"
    center
    >
    <el-upload
      :show-file-list="false"
      :before-upload="limitSize"
      drag
      action="#"
      :http-request="postFile"
      multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Suelta tu archivo aquí o <em>haz clic para cargar</em></div>
      <div slot="tip" class="el-upload__tip">Solo archivos Excel con un tamaño menor de 10Mb</div>
    </el-upload>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogImportFormVisible = false">Cancelar</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import { search, deleteProduct, uploadFile, getPage } from '@/api/shopifyProduct'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        keys: ''
      },
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      dialogImportFormVisible: false,
      sizePerPage: 25,
      stateKeys: ['Claves completas', 'Claves faltantes']
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleCreate () {
      this.$router.push({ name: 'shopify.product.create' })
    },
    handleEdit (index, row) {
      this.$router.push({ name: 'shopify.product.edit', query: { id: row._id } })
    },
    handleDelete (index, row) {
      this.$confirm('Se borrará permanentemente. ¿Desea continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deleteProduct(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
        .finally(() => (this.loading = false))
    },
    handleImport () {
      this.dialogImportFormVisible = true
    },
    limitSize (file) {
      var acceptFile = true
      const isLt10M = file.size / 1024 / 1024 < 10
      const isEXCEL = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isEXCEL) {
        this.$message.error('¡El archivo no está en formato Excel!')
        acceptFile = false
      }
      if (!isLt10M) {
        this.$message.error('¡El archivo excede los 10MB!')
        acceptFile = false
      }
      return acceptFile
    },
    async postFile (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('type', param.file.type)
      uploadFile(formData).then((response) => {
        param.onSuccess(response)
        this.$message({
          showClose: true,
          message: 'Se ha cargado el archivo!',
          type: 'success'
        })
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        keys: ''
      }
      this.handleSearchFilter()
    }
  }
}
</script>
